export type AdvisorGroupJSON = {
  id: number;
  title: string;
  name: string;
  name_abbreviated: string;
  key: string;
  invite_id?: string;
  promo_code?: string;
  disable_monthly_plan: boolean;
  bill_institution: boolean;
  disable_consumer_access_to_forms?: boolean;
  has_advisor_access_to_forms?: boolean;
  access_id?: number;
  access: AdvisorGroupAccessJSON;
};

export class AdvisorGroup {
  static path = "/advisor-groups";
  static awsPath = "/advisorgroups";

  constructor(
    public id?: number,
    public title?: string,
    public name?: string,
    public nameAbbreviated?: string,
    public key?: string,
    public inviteId?: string,
    public promoCode?: string,
    public disableMonthlyPlan?: boolean,
    public billInstitution?: boolean,
    public disableConsumerAccessToForms?: boolean,
    public hasAdvisorAccessToForms?: boolean,
    public accessId?: number,
    public access?: AdvisorGroupAccess
  ) { }

  static fromJSON = (json: AdvisorGroupJSON): AdvisorGroup => {
    return new AdvisorGroup(
      json.id,
      json.title,
      json.name,
      json.name_abbreviated,
      json.key,
      json.invite_id,
      json.promo_code,
      json.disable_monthly_plan,
      json.bill_institution,
      json.disable_consumer_access_to_forms,
      json.has_advisor_access_to_forms,
      json.access_id,
      json.access ? AdvisorGroupAccess.fromJSON(json.access) : undefined
    );
  };

  toJSON = (): any => {
    return {
      id: this.id,
      title: this.title,
      key: this.key,
      name: this.name,
      name_abbreviated: this.nameAbbreviated,
      invite_id: this.inviteId,
      access_id: this.accessId,
      disable_consumer_access_to_forms: this.disableConsumerAccessToForms,
      has_advisor_access_to_forms: this.hasAdvisorAccessToForms,
    };
  };
}

export type AdvisorGroupAccessJSON = {
  id?: number;
  access?: string;
  description?: string;
};

export class AdvisorGroupAccess {
  static path = "/advisor-group-access";

  constructor(
    public id?: number,
    public access?: string,
    public description?: string
  ) { }

  static fromJSON = (json: AdvisorGroupAccessJSON): AdvisorGroupAccess => {
    return new AdvisorGroupAccess(json.id, json.access, json.description);
  };
}
