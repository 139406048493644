import { Advisor } from "../models/Advisor";
import { AdvisorsEstates } from "../models/AdvisorsEstates";
import { callDelete, callGet, callPost, callPut, logger } from "./index";
import { AdvisorGroup } from "../models/AdvisorGroup";
import { Estate } from "../models/Estate";
import { isProd } from "../constants";
import { Subscription } from "../models/Subscription";
import { User, UserJSON } from "../models/User";

export type AdvisorsEstatesMap = {
  [key: string]: {
    advisorId: number;
    advisorGroupId: number;
    roleId: number;
    estates: AdvisorsEstates[];
  };
};

export type AdvisorsEstatesWithTotal = {
  estates?: AdvisorsEstates[];
  total?: number;
};

export class AtticusAdvisor {
  async fetchAdvisors(): Promise<Advisor[] | undefined> {
    const response = await callGet(Advisor.path);
    return response?.data?.map((json: UserJSON) => {
      const user = new User(
        json.id,
        json.cognito_uuid,
        undefined,
        json.email,
        json.first_name,
        json.last_name,
        undefined,
        undefined,
        undefined,
        json.role_id === 3,
        json.role_id === 2,
        json.role_id,
        undefined,
        json.advisor_group
          ? AdvisorGroup.fromJSON(json.advisor_group)
          : undefined,
        json.advisor_group_id
      );
      const estates = json?.estates?.map((estateJson: AdvisorsEstates) => {
        return AdvisorsEstates.fromJSON(estateJson);
      });
      return Advisor.fromUser(user, estates);
    });
  }

  async fetchAdvisorsEstatesMap(): Promise<AdvisorsEstatesMap | null> {
    const response = await callGet(Advisor.path);
    if (response?.data) {
      const advisorEstatesMap: AdvisorsEstatesMap = {};
      response.data.forEach((json: any) => {
        if (json.cognito_uuid) {
          advisorEstatesMap[json.cognito_uuid] = {
            advisorId: json.id,
            roleId: json.role_id,
            advisorGroupId: json.advisor_group_id,
            estates:
              json.estates &&
              json.estates.map((estateJson: any) => {
                return AdvisorsEstates.fromJSON(estateJson) || undefined;
              }),
          };
        }
      });
      return advisorEstatesMap;
    } else {
      logger.error("no response.data");
    }
    return null;
  }

  mergeSqlData(
    advisors: Advisor[],
    advisorsEstatesMap: AdvisorsEstatesMap
  ): Advisor[] {
    const mergedAdvisors: (Advisor | undefined)[] = advisors.map(
      (advisor: Advisor) => {
        if (
          advisor?.user?.cognitoUsername &&
          advisorsEstatesMap[advisor?.user?.cognitoUsername]
        ) {
          const data = advisorsEstatesMap[advisor.user.cognitoUsername];
          advisor.user.id = data.advisorId;
          advisor.user.advisorGroupId = data.advisorGroupId;
          advisor.user.roleId = data.roleId;
          advisor.estates = data.estates;
          return advisor;
        } else {
          return undefined;
        }
      }
    );
    // filter out undefined
    return mergedAdvisors.filter(
      (advisor: Advisor | undefined): advisor is Advisor =>
        advisor !== undefined
    );
  }

  /**
   * update advisors profile details
   *
   * @param body
   * @param rethrow
   */
  async updateAdvisor(body: UserJSON, rethrow?: false): Promise<boolean> {
    try {
      const response = await callPut(Advisor.path + "/me", body);
      if (response?.data?.message === "success") {
        return true;
      } else {
        throw Error("no data property");
      }
    } catch (error) {
      logger.error(error as Error);
      if (rethrow) throw error;
    }
    return false;
  }

  async createUserForInvite(email: string, roleId: number, groupName: string): Promise<boolean> {
    try {
      const response = await callPost(Advisor.path + "/create-user-for-invite", {
        email,
        role_id: roleId,
        advisor_group: groupName,
      });
      if (Math.floor((response?.status || 400) / 100) === 2) {
        return true;
      } else {
        throw Error("no data property");
      }
    } catch (error) {
      logger.error(error as Error);
      throw error;
    }

    return false;
  }

  /**
   * update advisors group or role
   *
   * @param userId
   * @param body - {advisor_group_id: advisorGroupId} || { role_id: roleId}
   * @param rethrow
   */
  async updateAdvisorAccess(
    userId: number,
    body: Record<string, number>,
    rethrow?: false
  ): Promise<boolean> {
    try {
      const response = await callPut(Advisor.path + "/" + userId, body);
      if (response?.data?.message === "success") {
        return true;
      } else {
        throw Error("no data property");
      }
    } catch (error) {
      logger.error(error as Error);
      if (rethrow) throw error;
    }
    return false;
  }

  async fetchAdvisorsEstates(
    advisorsUserId: number,
    limit?: number,
    offSet?: number
  ): Promise<AdvisorsEstatesWithTotal> {
    let url = AdvisorsEstates.path + "/advisors/" + advisorsUserId;
    if (limit !== undefined && offSet !== undefined)
      url += `?limit=${limit}&offset=${offSet}`;
    const response = await callGet(url);
    return {
      total: response?.data?.total,
      estates: response?.data?.estates?.map((json: any) =>
        AdvisorsEstates.fromJSON(json)
      ),
    };
  }

  async fetchEstatesAdvisors(estateId: number): Promise<AdvisorsEstates[]> {
    const url = AdvisorsEstates.path + "/estates/" + estateId;
    const response = await callGet(url);
    return response?.data?.map((json: any) => AdvisorsEstates.fromJSON(json));
  }

  async assignAdvisor(
    advisorsEstates: AdvisorsEstates
  ): Promise<AdvisorsEstates> {
    const response = await callPost(
      AdvisorsEstates.path,
      advisorsEstates.toJson()
    );
    if (response?.data) {
      return AdvisorsEstates.fromJSON(response.data);
    } else {
      logger.error("no response.data");
    }
    // empty user indicates not authenticated or error
    return new AdvisorsEstates();
  }

  async unassignAdvisor(advisorsEstatesId: number): Promise<boolean> {
    const response = await callDelete(
      AdvisorsEstates.path + "/" + advisorsEstatesId
    );
    if (response?.data) {
      return response.data;
    } else {
      logger.error("no response.data");
    }
    return false;
  }

  async updateAdvisorEstateAccess(
    advisorsEstates: AdvisorsEstates
  ): Promise<AdvisorsEstates> {
    const fullPath = `${AdvisorsEstates.path}/${advisorsEstates.advisorUserId}/estates/${advisorsEstates.estateId}`;
    const response = await callPut(fullPath, {
      has_write_access: advisorsEstates.hasWriteAccess,
    });
    if (response?.data) {
      return AdvisorsEstates.fromJSON(response.data);
    } else {
      logger.error("no response.data");
    }
    // empty user indicates not authenticated or error
    return new AdvisorsEstates();
  }

  async deleteAdvisor(advisorId: number): Promise<boolean> {
    const response = await callDelete(Advisor.path + "/" + advisorId);
    if (response?.data) {
      return response.data;
    } else {
      logger.error("no response.data");
    }
    return false;
  }

  async fetchAdvisorGroups(): Promise<AdvisorGroup[] | null> {
    const response = await callGet(AdvisorGroup.path);
    if (response?.data) {
      return response.data.map((json: any) => AdvisorGroup.fromJSON(json));
    } else {
      logger.error("no response.data");
    }
    return null;
  }

  getAdvisorGroupById(
    id?: number,
    advisorGroups?: AdvisorGroup[]
  ): AdvisorGroup | undefined {
    return id && advisorGroups
      ? advisorGroups.find(
        (advisorGroup: AdvisorGroup) => advisorGroup.id === id
      )
      : undefined;
  }

  async saveAdvisorGroupToLaravel(
    advisorGroup: AdvisorGroup,
    update = false
  ): Promise<AdvisorGroup | null> {
    try {
      let response;
      if (update) {
        response = await callPut(
          AdvisorGroup.path + "/" + advisorGroup.id,
          advisorGroup.toJSON()
        );
      } else {
        response = await callPost(AdvisorGroup.path, advisorGroup.toJSON());
      }
      if (response?.data?.id) {
        advisorGroup.id = response.data.id;
        return advisorGroup;
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async saveAdvisorGroupToCognito(
    advisorGroup: AdvisorGroup
  ): Promise<boolean> {
    try {
      const url = "https://advisor-groups-v2" + (isProd ? "" : "-ppd1") + ".weareatticus.com"
      const response = await callPost(url + "/" + AdvisorGroup.awsPath, advisorGroup.toJSON());
      if (response?.status && Math.floor(response.status / 100) == 2) {
        return true;
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return false;
  }

  async assignEstateToGroup(
    estateId: number,
    advisorGroupId: number
  ): Promise<boolean> {
    const response = await callPost(Estate.path + "/" + estateId, {
      advisor_group_id: advisorGroupId,
    });
    return response?.data?.message === "success";
  }

  async deleteEstate(estateId: number, forceDelete = false): Promise<boolean> {
    let path = Estate.path + "/" + estateId;
    if (forceDelete) path += "?force=true";
    const response = await callDelete(path);
    return response?.data?.message === "success";
  }

  async recoverEstate(estateId: number): Promise<boolean> {
    const response = await callPut(Estate.path + "/" + estateId + "/restore");
    return response?.data?.message === "success";
  }

  async fetchSubscription(estateId: number): Promise<Subscription | undefined> {
    const response = await callGet(`${Subscription.path}/${estateId}`);
    return response?.data?.message
      ? Subscription.fromJSON(response?.data?.message)
      : new Subscription();
  }
}
