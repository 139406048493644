import React from "react";
import {
  Button,
  Grid,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Formik, FormikHelpers } from "formik";
import { advisorApi, inviteApi, logger } from "../../../apis";
import { connect } from "react-redux";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { saveAdvisorGroup } from "../../../actions/advisorGroupActions";
import { ADVISOR_GROUP_PREFIX, InviteTypes } from "../../../constants";
import { Invite } from "../../../models/Invite";
const Yup = require('yup')

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(1),
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonsLeft: {
    "& > Button": {
      marginRight: theme.spacing(1),
    },
  },
  columnRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    margin: "2px 0",
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Values {
  title: string;
}

interface Props {
  advisorGroup: AdvisorGroup;
  onComplete: () => void;
  saveAdvisorGroup: (advisorGroup: AdvisorGroup) => void;
  colSpan?: number;
}

export const _AdvisorGroupForm = ({
  advisorGroup,
  onComplete,
  saveAdvisorGroup,
  colSpan = 6,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      title: advisorGroup.title || "",
    },
    schema: Yup.object().shape({
      title: Yup.string()
        .matches(/^\S+$/g, "Name can not contain spaces")
        .required("Name is required"),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, resetForm, setStatus }: FormikHelpers<Values>
  ) => {
    setStatus("");
    try {
      const confirmation = `Group names can not be changed after creation.\nAre you sure "${values.title}" is correct?`;
      if (!window.confirm(confirmation)) return;
      advisorGroup.title = ADVISOR_GROUP_PREFIX + values.title;
      advisorGroup.name = values.title;

      // NOTE: failure during this 5 step process will likely need to be fixed manually with some db manipulation,
      //   but it is unlikely to happen

      // 1. make call to cognito
      // this is no longer needed

      // 2. make call to laravel
      let updatedAdvisorGroup = await advisorApi.saveAdvisorGroupToLaravel(
        advisorGroup
      );
      if (!updatedAdvisorGroup)
        throw Error("laravel advisor group creation failed");
      // 3. create group invite
      const groupInviteId = await inviteApi.createInvite(
        new Invite(
          InviteTypes.Group,
          undefined,
          1,
          undefined,
          updatedAdvisorGroup.id
        )
      );
      if (!groupInviteId) throw Error("group invite create failed");
      // 4. save groupInviteId to laravel
      updatedAdvisorGroup.inviteId = groupInviteId;
      updatedAdvisorGroup = await advisorApi.saveAdvisorGroupToLaravel(
        updatedAdvisorGroup,
        true
      );
      if (!updatedAdvisorGroup)
        throw Error("laravel advisor group inviteId save failed");
      // 5. if both succeed save to client-side store
      await saveAdvisorGroup(updatedAdvisorGroup);
      setSubmitting(false);
      resetForm();
      onComplete();
    } catch (error) {
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      logger.error(error as Error);
      setSubmitting(false);
    }
  };

  return (
    <TableRow hover key="update-opportunity">
      <TableCell colSpan={colSpan}>
        <div className={classes.formWrapper}>
          <Formik
            initialValues={formikInit.values}
            validationSchema={formikInit.schema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12}>
                    <ul>
                      <li>
                        Name should be title cased. Example: BankOfAtticus
                      </li>
                      <li>Name can not contain spaces.</li>
                      <li>
                        A standard prefix of "AG-" will be added to your entry
                        below. Do not add the prefix.
                      </li>
                      <li>
                        Names can not be modified after creation. 🦉 Slow down
                        and choose wisely.
                      </li>
                    </ul>
                    <TextField
                      name="title"
                      label={
                        advisorGroup
                          ? "New Advisor Group name"
                          : "Advisor Group name"
                      }
                      variant="outlined"
                      className={classes.inputs}
                      fullWidth
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Typography variant="body1" color="error">
                      {errors.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonWrapper}>
                    {/* NOTE: keyboarding tabbing will reach this first*/}
                    <Grid item xs={6} className={classes.columnRight}>
                      <Typography
                        variant="body1"
                        color="error"
                        style={{ marginRight: 8 }}
                      >
                        {status}
                      </Typography>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonsLeft}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          resetForm();
                          onComplete();
                        }}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </TableCell>
    </TableRow>
  );
};

// const mapStateToProps = ({
//   user,
// }: StoreState): {
//   user: User;
// } => {
//   return {
//     user,
//   };
// };

export const AdvisorGroupForm = connect(null, {
  saveAdvisorGroup,
})(_AdvisorGroupForm);
