import React from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
const Yup = require("yup")
import { Formik, FormikHelpers } from "formik";
import { inviteApi, logger, advisorApi } from "../../../apis";
import { ASSIGNABLE_ROLES, InviteTypes, ROLE } from "../../../constants";
import { Invite } from "../../../models/Invite";
import { User } from "../../../models/User";
import { Link } from "react-router-dom";
import { AdvisorGroup } from "../../../models/AdvisorGroup";

const useStyles = makeStyles()(() => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputs: {
    margin: "15px 0",
  },
}));

interface Values {
  email: string;
  role: string;
  group: string;
}

interface Props {
  user?: User;
  advisorGroups: AdvisorGroup[];
}

export const InviteAdvisorForm = ({
  user,
  advisorGroups,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [inviteLink, setInviteLink] = React.useState("");
  const [invitationEmail, setInvitationEmail] = React.useState("");
  const showRoleSelect = user?.isAdvisorAdmin || false;
  const showGroupSelect = user?.isUberAdmin || false;
  const showGroupAssignmentWarning = user?.isUberAdmin && !user?.advisorGroupName;

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      email: "",
      role: "Advisor",
      group: user?.advisorGroupName || "",
    },
    schema: Yup.object().shape({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      role: Yup.string().required("Role is required"),
      group: Yup.string().required("Advisor group is required"),
    }),
  };

  const getInviteUrl = (inviteId: string): string => {
    return `${window.document.location.origin}/invite/${inviteId}`;
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, resetForm, setStatus }: FormikHelpers<Values>
  ) => {
    setStatus("");
    try {
      if (!user?.advisorGroupName) {
        setStatus(
          "Sorry, error: can not determine your advisor group required for data security."
        );
        setSubmitting(false);
        return;
      }
      const role = ASSIGNABLE_ROLES.filter(
        (role: ROLE) => values.role === role.title
      )[0];

      // Create user record itself in product, with the correct role and group.  This is to avoid a really annoying
      // and tough to understand group assignment loop that has to go through cognito
      await advisorApi.createUserForInvite(values.email, role.id, values.group)

      // Create invite for user to create login in cognito
      const invite = new Invite(InviteTypes.Advisor, values.email, role.id);
      if (user.isUberAdmin) invite.advisorGroup = values.group;
      const inviteId = await inviteApi.createInvite(invite);
      if (inviteId) {
        setInvitationEmail(values.email);
        setInviteLink(getInviteUrl(inviteId));
        setSubmitting(false);
        resetForm();
      } else {
        // display error
        setStatus(
          "Sorry, there was an error creating the invite.  Please try again."
        );
      }
    } catch (error) {
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      logger.error(error as Error);
      setSubmitting(false);
    }
  };

  // const goToDashboard = () => {
  //   navigate(`/app/dashboard`);
  // };

  return (
    <>
      {showGroupAssignmentWarning && (
        <>
          <Typography variant="h4" color="primary">
            It looks like your an Uber Admin and are not assigned to a group.
          </Typography>
          <Typography variant="h4">
            You'll need to assign you self to a group on the{" "}
            <Link to="/app/assignments">assignments screen</Link> before sending
            and invite to that group.
          </Typography>
        </>
      )}
      <Formik
        initialValues={formikInit.values}
        validationSchema={formikInit.schema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
          status,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  name="email"
                  label="New advisor's email"
                  variant="outlined"
                  className={classes.inputs}
                  fullWidth
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Typography variant="body1" color="error">
                  {errors.email}
                </Typography>
              </Grid>
              {showGroupSelect && (
                <Grid item xs={12}>
                  <Select
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    variant="outlined"
                    displayEmpty={true}
                  >
                    {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                      return (
                        <MenuItem
                          value={advisorGroup.title}
                          key={advisorGroup.id}
                        >
                          {advisorGroup.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              )}
              {showRoleSelect && (
                <Grid item xs={12}>
                  <Select
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    variant="outlined"
                    displayEmpty={true}
                  >
                    {ASSIGNABLE_ROLES.map((role: ROLE) => {
                      return role.title === "Uber" ? null : (
                        <MenuItem value={role.title} key={role.id}>
                          {role.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="error"
                  style={{ margin: "10px 0" }}
                >
                  {status}
                </Typography>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create a sign up invite link
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  type="button"
                  style={{ marginLeft: 8 }}
                  onClick={() => {
                    resetForm();
                    setInviteLink("");
                    setInvitationEmail("");
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12}>
                {isSubmitting && (
                  <Typography variant="h4">Creating link...</Typography>
                )}
                {inviteLink && invitationEmail && (
                  <>
                    <br />
                    <Typography variant="h4">
                      Invitation link for <strong>{invitationEmail}</strong>:
                    </Typography>
                    <br />
                    <Typography variant="h5" color="primary">
                      <a href={inviteLink} target="_blank">
                        {inviteLink}
                      </a>
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
