import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Auth, I18n } from "aws-amplify";
import { defaultServices } from "@aws-amplify/ui/dist/types/machines/authenticator/defaultServices";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // A lot of these styles will override global styles :(  Seems like that is current behavior so I'll leave it like it is
import { authApi, inviteApi } from "../../apis";
import { SCREEN_PATHS, AUTH_ERRORS, STORAGE_KEYS } from "../../constants";
import Page from "../../components/Page";
import { SupportAnchorLink } from "../shared/SupportAnchorLink";
import { getQueryStringParams } from "../../utils/getQueryStringParams";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUser } from "../../actions/userActions";
import { User } from "../../models/User";
import { EnvironmentInputs } from "./EnvironmentInputs";
import { PreProdOnlyWrapper } from "../shared/PreProdOnlyWrapper";
import { ISignUpResult } from "amazon-cognito-identity-js";

I18n.putVocabulariesForLanguage("en", {
  "Create Account": "Sign Up", // Tab header
  "Given Name": "First Name",
  "Family Name": "Last Name",
  "Username": "Email",
  "Enter your Username": "Enter your Email",
});

interface Props {
  setUser: (user: User) => void;
}

export const _HostedAuth = ({ setUser }: Props): JSX.Element => {
  const navigate = useNavigate();
  const params = getQueryStringParams();
  const inviteId = params?.invite
  const services: Partial<typeof defaultServices> = {
    async handleSignIn(formData: any) {
      console.log("Handling sign in")
      const { username, password } = formData;
      const cognitoUser = await Auth.signIn({
        username,
        password,
      });
      await authApi.getAndSetAuthToken();
      // get user and save to global redux store
      const user = await authApi.getUser();
      if (user) {
        setUser(user);
      } else {
        throw Error("sign in failed");
      }
      return cognitoUser;
    },
    async handleSignUp(formData: any) {
      console.log("Handling sign up")
      const { username, password, attributes } = formData;
      if (inviteId) {
        attributes["custom:invite_id"] = inviteId;
      }
      attributes["custom:is_advisor"] = "true";
      let cognitoUser: ISignUpResult = {} as ISignUpResult
      try {
        cognitoUser = await Auth.signUp({
          username,
          password,
          attributes,
          autoSignIn: {
            enabled: true,
          },
        });
      } catch (e) {
        console.log((e as any)?.message)
      }

      if (!inviteId) {
        return cognitoUser;
      }

      // make a call to redeem the invite on the backend 
      if (await inviteApi.redeemInvite(inviteId)) {
        return cognitoUser;
      } else {
        throw new Error(AUTH_ERRORS.inviteRedemption);
      }
    },
  };

  const signInComponents = {
    Footer: () => (
      <Box
        sx={{
          my: 2,
          mx: 0,
          textAlign: "center",
        }}
      >
        {process.env.REACT_APP_SUPPORT_EMAIL && <Typography variant="body2">
          If you are having difficulties, please contact us at:{" "}
          <SupportAnchorLink />
        </Typography>}
      </Box>
    ),
  };

  const getAuthWrapperStyles = () => {
    return {
      // https://ui.docs.amplify.aws/components/authenticator#styling
      "[data-amplify-authenticator] [data-amplify-container]": {
        width: "100%",
      },
      ".amplify-button[type=submit]": {
        backgroundColor: "primary.main",
        borderRadius: "24px !important", // * hack required because aws is adding an inline style
        textTransform: "none",
        minWidth: 180,
        boxShadow: "none",
        fontSize: 16,
        fontWeight: "bold !important", // *
        padding: "10px 16px",
        margin: "10px 0",
      },
      ".amplify-button[type=submit] + .amplify-button[type=button]": {
        color: "primary.main",
        backgroundColor: "common.white",
        borderRadius: "24px !important", // * hack required because aws is adding an inline style
        borderColor: "primary.main",
        borderWidth: "1px",
        textTransform: "none",
        minWidth: 180,
        boxShadow: "none",
        fontSize: 16,
        fontWeight: "bold !important", // *
        padding: "10px 16px",
        margin: "10px 0",
      },
      ".federated-sign-in-button": {
        borderRadius: 20,
        padding: "6px 16px",
      },
      "[data-amplify-authenticator] [data-amplify-router]": {
        boxShadow: "none",
        border: "none",
      },
      ".amplify-tabs[data-indicator-position=top]": {
        borderWidth: 0,
        position: "relative",
        top: -18,
      },
      ".amplify-tabs-item": {
        color: "grey.500",
        borderRadius: 28,
        border: "none",
      },
      ".amplify-tabs-item:nth-of-type(1)": {
        borderRadius: "28px 28px 28px 0",
      },
      ".amplify-tabs-item:nth-of-type(2)": {
        borderRadius: "28px 28px 0 28px",
      },
      ".amplify-tabs-item[data-state=inactive]": {
        backgroundColor: "grey.200",
      },
      ".amplify-tabs-item[data-state=inactive]:hover": {
        color: "common.white",
        backgroundColor: "primary.main",
      },
      ".amplify-tabs-item[data-state=active]": {
        color: "primary.main",
        backgroundColor: "common.white",
      },
      ".amplify-textfield": {
        marginTop: "8px",
        marginBottom: "8px",
      },
      ".amplify-button[data-variation=link]": {
        color: "primary.main",
      },
      ".amplify-button[data-variation=link]:hover": {
        backgroundColor: "background.default",
        textDecoration: "underline",
      },
      "h3.amplify-heading": {
        fontSize: "var(--amplify-components-heading-4-font-size)",
        fontWeight: "bold",
      },
      // hide the sms account verification
      "[data-amplify-authenticator-verifyuser] .amplify-radiogroup label:nth-of-type(2)":
      {
        display: "none",
      },
    };
  };

  return (
    <Page title="Atticus Authentication">
      <Box
        sx={{
          width: "100%",
          maxWidth: 575,
          mx: "auto",
          my: 2,
          pt: 4,
        }}
      >
        <Card
          sx={{
            border: "none",
            overflow: "visible",
          }}
        >
          <CardContent
            sx={{
              p: 0,
            }}
          >
            <Box sx={getAuthWrapperStyles()}>
              <Authenticator
                initialState={inviteId ? "signUp" : "signIn"}
                services={services}
                signUpAttributes={["given_name", "family_name", "phone_number"]}
                components={signInComponents}
                hideSignUp={!inviteId}
              >
                {() => {
                  window.localStorage.removeItem(STORAGE_KEYS.AUTH_REDIRECT_FROM)
                  navigate(SCREEN_PATHS.dashboard + "?signupSuccess=true")
                  return <></>;
                }}
              </Authenticator>
            </Box>
          </CardContent>
        </Card>
        <PreProdOnlyWrapper>
          <EnvironmentInputs />
        </PreProdOnlyWrapper>
      </Box>
    </Page>
  );
};

export const HostedAuth = connect(null, { setUser })(_HostedAuth);
