import { AtticusChips, Chip } from "../../models/Chip";

export type Resource = {
  title?: string;
  brief?: string;
  url?: string;
  chips?: Chip[];
};

export const resourcesContent: Resource[] = [
  {
    title: "What To Do When Someone Dies: The 2024 Checklist",
    brief:
      "Losing a loved one can be incredibly difficult, both emotionally and logistically. Here is the definitive guide and checklist outlining all of the important things that need to be considered following the loss of a loved one in 2024.",
    url: "https://www.weareatticus.com/articles/what-to-do-when-loved-one-dies",
    chips: [AtticusChips["guide"]],
  },
  {
    title: "What is Probate: The Beginner's Guide",
    brief:
      "Let's start with the basics— Probate is simply the government's way of making sure that when a person dies, the right stuff goes to the right people (including any taxes the government wants).",
    url: "http://www.weareatticus.com/probate/what-is-probate",
    chips: [AtticusChips["guide"]],
  },
  {
    title: "The Beginner's guide to serving as an Executor: How to Succeed",
    brief:
      "Being the executor of an estate comes with a whirlwind of responsibilities and duties. Here's the guide for doing this process right.",
    url: "http://www.weareatticus.com/probate/executor-of-estate",
    chips: [AtticusChips["guide"]],
  },
  {
    title: "What is Domicile & how it impacts inheritance",
    brief:
      "Someone's domicile can have big implications in family law, probate, and inheritance. Here's what domicile is, how to prove it, and details on affidavits of domicile.",
    url: "http://www.weareatticus.com/probate/what-is-domicile",
    chips: [AtticusChips["guide"]],
  },
  {
    title: "Complete Guide to Probate vs. Non-Probate Assets",
    brief:
      "Not sure which assets qualify as non-probate vs. probate? Here's a comprehensive guide to help understand the rules and (hopefully) avoid unnecessary probate.",
    url: "http://www.weareatticus.com/probate/difference-between-probate-and-non-probate-assets",
    chips: [AtticusChips["guide"]],
  },
  {
    title: "Nation's largest repository of Probate & Estate Settlement Forms",
    brief:
      "The largest repository of jurisdiction-specific probate & estate settlement court forms from jurisdictions across North America.",
    url: "https://forms.weareatticus.com/",
    chips: [AtticusChips["tool"]],
  },
  {
    title: "Probate Court & Probate Clerk Directory",
    brief:
      "Search for and review helpful information about local probate courts and probate clerks nationally. Includes helpful links, frequently asked questions, testimonies and reviews.",
    url: "https://www.weareatticus.com/probate-court-directory",
    chips: [AtticusChips["tool"]],
  },
  {
    title: "The Executor’s Glossary",
    brief:
      'Simple, contextual definitions to confusing industry terms, empowering every "Joe" into becoming a "Pro"',
    url: "https://www.weareatticus.com/glossary",
    chips: [AtticusChips["resource"]],
  },
  {
    title: "Resources Learn Hub",
    brief:
      "Comprehensive, step-by-step guides and curated articles by industry advisors designed to help get navigate every stage of the estate settlement process with confidence",
    url: "https://www.weareatticus.com/learn",
    chips: [AtticusChips["resource"]],
  },
  {
    title: "Atticus Magazine",
    brief:
      "Highlighting helpful stories, learnings & trends for executors & advisors by our community of users, partners & industry pros",
    url: "https://www.weareatticus.com/magazine",
    chips: [AtticusChips["resource"]],
  },
];
